<template>
  <div>
    <template>
      <div>
        <ScrollupSection />
        <div class="main overflow-hidden">
          <HeaderSection />
          <PerfilBreadcrumb :titulo="titulo" :descripcion="descripcion" />
          <section id="service" class="section service-area my-5">
            <div class="text-container">
              <p>
                En ElectoralTech Legal tomamos su privacidad seriamente y tomamos en cuenta las posibles inquietudes sobre la manera en 
                que utilizamos la información personal que recopilamos de usted y sobre usted. Además de esta política de privacidad, 
                tenemos políticas y prácticas internas destinadas a mantener su información personal segura y para garantizar que 
                la información sobre usted solo se utilice de acuerdo con esta política de privacidad. 
              </p> <br>
              <p>
                Aplicamos activamente los principios de privacidad por designio, y tenemos un equipo de privacidad que se compromete 
                a ayudar para garantizar que su información personal esté segura y se maneje adecuadamente.
              </p> <br>
              <p>
                Esta política de privacidad describe cómo recopilamos, usamos, procesamos y revelamos la información personal de los consumidores lo anterior 
                aplicándose a electoraltech.com.mx, así como a las subsidiarias y afiliadas de ElectoralTech Legal.
                El alcance de la política de privacidad abarca tanto su uso del sitio web electoraltech.com.mx, como 
                cualquier otra forma en la que usted pueda interactuar con ElectoralTech Legal, como por ejemplo cuando nos habla por teléfono. 
                Asimismo, describe las opciones de las que dispone respecto al uso que hacemos con su información personal. 
                También se incluye en esta política la información para los residentes de Veracruz y las personas ubicadas en la República Mexicana.
              </p> <br>

              <p>
                Esta Política de privacidad se incorpora a nuestros términos de uso y términos de servicio y, por lo tanto, 
                rige el uso que usted hace de los servicios de ElectoralTech Legal. 
                Al usar los servicios de ElectoralTech Legal, usted acepta los términos de esta política de privacidad. 
                Si tiene preguntas o inquietudes sobre la Política de privacidad o desea ejercer sus derechos respecto a 
                la forma cómo se usan sus datos, puede contactarnos mediante el formulario que se encuentra aquí.
              </p><br>
              <div class="centered">
                <div class="contact-box-container">
                  <!-- Contact Box -->
                  <div class="contact-box text-center">
                    <!-- Contact Form -->
                    <form @submit.prevent="submit">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                name="name"
                                v-model.trim="$v.form.nombre.$model"
                                :class="{ error: $v.form.nombre.$error }"
                                placeholder="Nombre"
                            />
                          </div>
                          <div class="form-group">
                            <input
                                type="email"
                                class="form-control"
                                name="email"
                                v-model.trim="$v.form.email.$model"
                                :class="{ error: $v.form.email.$error }"
                                placeholder="Email"
                            />
                          </div>
                          <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                name="subject"
                                v-model.trim="$v.form.telefono.$model"
                                :class="{ error: $v.form.telefono.$error }"
                                placeholder="Telefono"
                            />
                            <div style="text-align: left; color: #9e9e9e">
                              <small>Número télefono a 10 digitos, solo números.</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">

                          <label style="color: #828282">¿Cómo prefieres que te contactemos?</label>
                          <br>

                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="form.preferencia" id="tel" value="1">
                            <label class="form-check-label" for="tel" style="color: #828282">
                              Teléfono
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="emailOp" v-model="form.preferencia" value="2">
                            <label class="form-check-label" for="emailOp" style="color: #828282">
                              Email
                            </label>
                          </div>

                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="whats" v-model="form.preferencia" value="3">
                            <label class="form-check-label" for="whats" style="color: #828282">
                              What's app
                            </label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <textarea
                                class="form-control"
                                name="message"
                                v-model.trim="$v.form.comentario.$model"
                                :class="{ error: $v.form.comentario.$error }"
                                placeholder="¿En qué te podemos ayudar?"
                            ></textarea>
                          </div>
                        </div>
                        <div class="col-12" v-if="!$v.$invalid">
                          <div class="form-group">
                            <vue-recaptcha
                                ref="recaptcha"
                                @verify="onVerify"
                                :sitekey="key"
                                @expired="onExpired"
                            >
                            </vue-recaptcha>
                          </div>
                        </div>
                        <div class="col-12">
                          <button
                              type="submit"
                              class="btn btn-bordered active btn-block mt-3"
                              :disabled="!captcha"
                          >
                            <span class="text-white pr-3"
                            ><i class="fas fa-paper-plane"></i></span
                            >Enviar mensaje
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              
              <p>
                Alternativamente, puede comunicarse con atención al cliente a través del correo electrónico: 
                <a href="mailto: contacto@electoraltech.com.mx">contacto@electoraltech.com.mx </a> o 228 307 8010. Si usted es un consumidor con alguna discapacidad y 
                tiene problemas para acceder a esta política de privacidad, puede solicitar que se la enviemos 
                mediante un correo electrónico dirigido a <a href="mailto: contacto@electoraltech.com.mx">contacto@electoraltech.com.mx </a> o llamando al 228 307 8010.
              </p><br>
              <p>
                Observación: Esta política de privacidad no cubre la información que usted pudiese proporcionar en 
                relación con el uso que hace de los servicios de protección de identidad o protección de datos comerciales de ElectoralTech Legal.
                Por el contrario, la información que usted proporciona en relación con esos servicios se rige 
                por la política de privacidad que se describe y autoriza al momento de la activación.	
              </p><br>
              <p>
                <span style="font-size: 22px;"><b>Información que recopilamos sobre los consumidores</b></span><br>
                En esta sección de la Política de privacidad, explicamos qué tipos de Información personal podemos 
                recopilar sobre los consumidores. La Información personal recopilada depende del tipo de interacción 
                que tengamos con un consumidor (como llamadas, visitas a nuestro sitio web o uso de nuestras aplicaciones móviles).
              </p><br>
              <p>
                Esta sección incluye toda la información que podemos recopilar sobre los consumidores, pero rara 
                vez recopilamos todos estos tipos de información sobre una sola persona. 
                Tal y como se utiliza en esta Política de privacidad, “Información personal” significa la información 
                que se relaciona, y se puede vincular, con una persona en particular. No incluye información anonimizada o totalizada.
              </p><br>
              <p>
                ElectoralTech Legal recopila posiblemente las siguientes categorías de información acerca de los consumidores, 
                algunas de las cuales pueden ser recopiladas o mantenidas de tal manera que pueden considerarse Información personal:
              </p><br>
              <p>
                <b>Identificadores: </b> Los ejemplos de los tipos de identificadores que ElectoralTech Legal podrá recopilar 
                incluyen nombres reales, apodos, direcciones postales, identificadores en línea, direcciones de protocolo 
                de Internet (“direcciones IP”), direcciones de correo electrónico, números de cuenta, números de seguro social, 
                números de licencia de conducir, números de pasaporte, credencial para votar, números de cédula profesional y 
                otros números de identificación del gobierno.
              </p><br>
              <p>
                <b>Otros tipos de información: </b>Algunos ejemplos de otros tipos de información incluyen características físicas o 
                descripciones de usted, número de teléfono, número de póliza de seguro, educación, empleo, información financiera, 
                incluyendo de cuentas bancarias, tarjetas de crédito y débito e información sobre el seguro de salud. Asimismo, 
                incluye información adicional proporcionada a ElectoralTech Legal directamente por usted, como cuando completa 
                un cuestionario, responde una encuesta o recibe una consulta financiera. Esta categoría incluye información 
                identificada como Información personal en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
              </p><br>
              <p>
                <b>Características protegidas conforme con algunas leyes:</b> Los ejemplos de los tipos de características que ElectoralTech Legal 
                podrá recopilar incluyen edad, origen nacional, ciudadanía, estado civil, información médica, sexo y orientación sexual.
              </p><br>
              <p>
                <b>Información comercial:</b> Los ejemplos de los tipos de información comercial que ElectoralTech Legal podrá recopilar incluyen 
                registros de negocios y otros bienes de su propiedad, productos o servicios adquiridos, obtenidos o considerados de 
                ElectoralTech Legal o de nuestros socios, y otras historias o tendencias de compra.
              </p><br>

              <p>
                <b>Información de Internet: </b>Los ejemplos de los tipos de información de Internet u otra actividad de red electrónica 
                que ElectoralTech Legal podrá recopilar incluyen el historial de navegación, el historial de búsqueda, información 
                sobre el dispositivo que el consumidor está usando para acceder a nuestro sitio web o aplicaciones, e información 
                relativa a la interacción del consumidor con los sitios web, aplicaciones o anuncios de ElectoralTech Legal o de 
                nuestros socios. También podemos recopilar información de los visitantes de nuestro sitio web, como la configuración 
                del navegador, los sistemas operativos, las páginas de referencia/salida y los datos del flujo de clics. Cuando le 
                enviamos un correo electrónico, ElectoralTech Legal también podrá recopilar información, incluyendo la forma en que 
                usted interactúa con ese correo electrónico.
              </p><br>

              <p>
                <b>Información de geolocalización: </b> ElectoralTech Legal podrá recopilar información acerca de dónde reside o está ubicado un consumidor.
              </p><br>

              <p>
                <b>Información de audio y visual: </b>ElectoralTech Legal puede hacer grabaciones de audio de las llamadas con los consumidores. 
                Podremos realizar grabaciones visuales de los consumidores cuando visitan nuestras instalaciones o cuando los consumidores 
                aceptan que ElectoralTech Legal les tome una fotografía o les grabe en video. También podremos recopilar imágenes visuales 
                de los consumidores cuando se nos proporcionen en relación con un producto, como por ejemplo cuando se envían como parte 
                de un pedido de una marca comercial.
              </p><br>

              <p>
                <b>Información profesional y relacionada con el empleo:</b> Los ejemplos de los tipos de información profesional y relacionada con 
                el empleo que ElectoralTech Legal podrá recopilar incluyen información sobre el estado profesional y las afiliaciones, 
                antecedentes laborales y situación laboral.<br>
                No recopilaremos información biométrica ni de educación que se mantenga en una institución educativa o una parte que actúe 
                en nombre de una institución educativa. Tampoco recopilaremos inferencias sobre los consumidores, aunque como se describe a 
                continuación, podemos crear internamente inferencias basadas en la información que sí recopilamos.
              </p><br>

              <p>
                <span style="font-size: 22px;"><b>Dónde recopilamos la información sobre los consumidores</b></span><br>
                En esta sección de la política de privacidad, explicamos dónde y cómo recopilamos datos sobre los consumidores. 
                Recibimos información sobre los consumidores de varias categorías de fuentes. Para obtener más información sobre 
                las categorías de información que recopilamos de cada fuente, consulte el cuadro de la sección que figura a 
                continuación, titulado Resumen de las prácticas de ElectoralTech Legal con respecto a la información de los consumidores.
              </p><br>

              <p>
                <b>Consumidores: </b> La gran mayoría de la información que ElectoralTech Legal recopila procede directa o indirectamente 
                del consumidor al que la misma se refiere. La información que se recopila directamente de usted es la que usted 
                proporciona cuando completa un formulario en línea, utiliza una función de chat o habla con nosotros por teléfono. 
                También podemos recopilar información indirectamente sobre usted cuando interactúa con nuestro sitio web, a través 
                del uso de cookies y otras tecnologías de seguimiento. 
                La recopilación indirecta se describe con más detalle en la sección que aparece a continuación denominada 
                “Cómo ElectoralTech Legal utiliza cookies y tecnologías similares”.
              </p><br>

              <p>
                <b>Proveedores de servicios:</b> Podremos recopilar información sobre usted a través de nuestros Proveedores de Servicios. 
                Tal y como se utiliza en esta Política de privacidad, los “Proveedores de servicios” significa las entidades 
                que ElectoralTech Legal ha contratado para proporcionarnos servicios para ayudar a satisfacer nuestras necesidades 
                comerciales. Un ejemplo del tipo de información que podríamos recibir de un Proveedor de Servicios es la información 
                sobre si se ha recibido algún Correo legal como parte de nuestros Servicios Registrados. Los Proveedores de Servicios 
                también pueden ayudarnos a recopilar información tanto directa como indirectamente de usted.
              </p><br>

              <p>
                <b>Profesionales (incluyendo Abogados especializados del directorio y otros Profesionales):</b> Podemos recopilar 
                información sobre usted de los profesionales independientes con los que usted pudiera programar una consulta 
                a través de nuestro Plan Legal u otras ofertas de productos. Un ejemplo del tipo de información que podemos 
                recibir de estos profesionales incluye el hecho de que se haya realizado una consulta. Si bien no podemos 
                controlar la información que los abogados del directorio comparten con nosotros, ElectoralTech Legal no 
                tiene la intención de recopilar ninguna información de los abogados cuando la recopilación resultase en una 
                renuncia a la relación de privacidad entre abogado y cliente.
              </p><br>

              <p>
                <b>Socios y afiliados comerciales: </b> Podemos recopilar información sobre usted de nuestros socios y afiliados comerciales. 
                Un ejemplo del tipo de información que podremos recibir de los socios y afiliados comerciales es la información 
                sobre el producto o servicio que usted les compró después de que usted haya indicado interés en una oferta que ElectoralTech Legal le presentó.
              </p><br>

              <p>
                <b>Información disponible a nivel público/comercial: </b>Podemos recopilar información relacionada con usted a partir de fuentes 
                de información disponibles públicamente, incluidos registros gubernamentales y fuentes de información del consumidor 
                disponibles a nivel comercial. Los ejemplos del tipo de información que podemos recopilar de fuentes disponibles 
                públicamente son si su entidad está al día con la Secretaría de Estado o si recientemente usted ha expresado 
                interés en comprar un producto o servicio que ElectoralTech Legal vende.
              </p><br>

              <p>
                <b>Partes no relacionadas:</b> Podemos recibir información relacionada con usted de personas y entidades con las 
                que ElectoralTech Legal no tiene una relación directa. Un ejemplo del tipo de información que podremos 
                recibir de terceros no relacionados es la documentación que recibimos en su nombre como agentes registrados.
              </p><br>

              <p>
                <span style="font-size: 22px;"><b>Cómo utilizamos la información que recopilamos sobre los consumidores</b></span><br>
                En esta sección de la Política de privacidad, explicamos nuestros propósitos comerciales y de negocios para 
                la recopilación de información sobre los consumidores. ElectoralTech Legal utiliza la información que ha 
                recopilado sobre los consumidores para los fines que se indican a continuación. En algunos casos, 
                podemos combinar la información que hemos recopilado de múltiples fuentes, para cumplir con estos propósitos. 
                ElectoralTech Legal utiliza posiblemente cada categoría de información que recopilamos para cada uno de estos fines.
              </p><br>

              <p>
                Si, en el futuro, ElectoralTech Legal desea utilizar la información sobre usted de una manera que no sea coherente 
                con los propósitos que se describen a continuación, solicitaremos su consentimiento antes de la ampliación del 
                uso y en los casos en que se nos exija legalmente.
              </p><br>

              <p>
                <b>Proporcionar productos y servicios:</b> ElectoralTech Legal recopila información con el fin de proporcionar productos 
                y servicios. También podemos utilizar la información para responder a las consultas de clientes potenciales que 
                estén interesados en conocer más sobre nuestros productos y servicios. Algunos ejemplos de los tipos de usos de 
                la información del consumidor para estos fines incluyen la producción y entrega de documentos adquiridos por los 
                consumidores, la administración del sitio web de ElectoralTech Legal y la visualización de contenido basado en 
                las preferencias de los consumidores.
              </p><br>

              <p>
                <b>Seguridad: </b>ElectoralTech Legal recopila información con el propósito de asegurar que mantenemos la seguridad 
                adecuada en las redes informáticas y en nuestras instalaciones físicas. También podemos recolectar información 
                con el propósito de detectar fraudes u otras actividades sospechosas.
              </p><br>

              <p>
                <b>Cumplimiento de los requisitos legales:</b> ElectoralTech Legal recopila información para cumplir con nuestras 
                obligaciones legales. Estas obligaciones pueden incluir el cumplimiento de los requisitos de investigación 
                antes de ayudar con las formaciones de la entidad, el mantenimiento de los registros como se nos exige en 
                calidad de Agentes Registrados, o responder a las citaciones judiciales para presentar información.
              </p><br>

              <p>
                <b>Mercadotecnia: </b> ElectoralTech Legal recopila información para utilizar en mercadotecnia para clientes 
                actuales y futuros. Encontrará más información acerca de cómo usted puede controlar el mercadeo que 
                recibe de ElectoralTech Legal en las secciones “Cómo ElectoralTech Legal utiliza cookies y tecnologías 
                similares” y “Cómo usted puede controlar la información que ElectoralTech Legal puede tener acerca de usted”.
              </p><br>

              <p>
                <b> Desarrollo de productos y servicios: </b> ElectoralTech Legal recopila información para mejorar nuestros 
                  productos y servicios ya existentes y desarrollar otros nuevos.
              </p><br>

              <p>
                <b>Analítica: </b> ElectoralTech Legal recopila información para su uso en la realización de diversos tipos de análisis. 
                Por ejemplo, ElectoralTech Legal utiliza la información para analizar cómo los visitantes interactúan con 
                nuestro sitio web, de dónde llegan y hacia dónde salen los visitantes de nuestro sitio web, y para obtener 
                información sobre los tipos de consumidores que pueden estar interesados en nuestros productos y servicios. 
                También podemos utilizar la información para desarrollar inferencias a partir de la información que recogemos 
                con respecto a las preferencias, características, predisposiciones, comportamientos y actitudes de los consumidores.
              </p><br>

              <p>
                <b> Propósitos operativos: </b> ElectoralTech Legal recopila información para cumplir con nuestros propósitos operativos, 
                y los propósitos operacionales de nuestros proveedores de servicios y socios comerciales. Por ejemplo, 
                ElectoralTech Legal puede usar la información para el mantenimiento de nuestro sitio web y otra infraestructura, 
                capacitación y control de calidad, para realizar encuestas o sorteos, para promover nuestra marca y para usarla 
                en conexión con nuestras relaciones con proveedores de servicios y socios comerciales.
              </p><br>

              <p>
                <b>Compartir con socios comerciales:</b> ElectoralTech Legal recopila información para proporcionar a los socios 
                comerciales información relacionada con los consumidores que han expresado interés en saber del socio 
                comercial o que han comprado un paquete de productos a través de ElectoralTech Legal.
              </p><br>

              <p>
                <b>Informes financieros:</b> ElectoralTech Legal recopila información para su uso en varios tipos de informes 
                financieros y otros usos internos similares. Algunos ejemplos de los tipos de informes financieros en 
                los que ElectoralTech Legal puede utilizar la información de los consumidores son los presupuestos y 
                proyecciones internas, el cálculo de la compensación y los informes para los inversores.
              </p><br>

              <p>
                <b>Transferencias comerciales:</b> Si ElectoralTech Legal participa o está involucrado en alguna fusión, 
                adquisición, reorganización, venta de activos, quiebra o evento de insolvencia, entonces podremos 
                vender, transferir o compartir algunos o todos nuestros activos, incluyendo la información relativa 
                a los consumidores en relación con la transacción o al considerar la transacción (como la realización 
                de la debida diligencia). Si ocurre dicho hecho, su información podrá ser tratada como cualquier otro 
                activo de ElectoralTech Legal y vendida, transferida o compartida con terceros, o usada en formas no 
                contempladas o permitidas en virtud de esta política de privacidad. En este caso, se le notificará por 
                correo electrónico y/o mediante una notificación prominente en nuestro sitio sobre cualquier cambio en 
                la propiedad o usos de su Información personal, así como la elección que usted pueda tener respecto 
                a su Información personal.
              </p><br>

              <p>
                Además de los fines mencionados anteriormente, ElectoralTech Legal también podrá utilizar datos 
                anonimizados o totalizados que no puedan relacionarse con ningún consumidor individual para otros 
                fines no mencionados anteriormente.
              </p><br>

              <p>
                <span style="font-size: 22px;"><b>Cómo y con quién compartimos la información del consumidor</b></span><br>
                En esta sección de la política de privacidad, explicamos con quién compartimos la información del consumidor 
                y en qué circunstancias podremos hacerlo. Para obtener más información sobre las categorías de información 
                que compartimos con los terceros, consulte el cuadro de la sección que figura a continuación, titulado 
                Resumen de las prácticas de ElectoralTech Legal con respecto a la información de los consumidores.
              </p><br>

              <p>
                <b>Proveedores de servicios:</b> ElectoralTech Legal podrá compartir información sobre los consumidores 
                con los proveedores de servicios que nos ayudan a cumplir los propósitos comerciales descritos 
                en la sección “Cómo utilizamos la información que recopilamos sobre los consumidores”. Estas 
                empresas no están autorizadas a usar la información que compartimos con ellas para ningún otro 
                propósito que no sea el de prestar servicios a ElectoralTech Legal o a usted.
              </p><br>

              <p>
                <b>Profesionales (incluyendo Abogados y otros Profesionales): </b> ElectoralTech Legal podrán compartir 
                información sobre el consumidor con los profesionales independientes a los que se puede acceder 
                a través de productos, tales como nuestros planes legales y productos asistidos por abogados.
              </p><br>

              <p>
                <b>Socios y afiliados comerciales:</b> ElectoralTech Legal podrá compartir información con socios comerciales 
                y afiliados en circunstancias limitadas. Estas incluyen:
              </p><br>

              <p>
                Si usted demuestra interés en una oferta de un socio comercial, podremos proporcionarle 
                información sobre usted en relación con la oferta que ha seleccionado. Si luego decide 
                optar por no permitir que ElectoralTech Legal comparta su información con socios comerciales 
                para sus fines de mercadotecnia, podrá hacerlo comunicándose con Atención al cliente a 
                <a href="mailto: contacto@electoraltech.com.mx">contacto@electoraltech.com.mx </a> o al 2283 078010. En la medida que ElectoralTech Legal ya 
                hubiese compartido su información antes de su decisión de excluirse, usted podrá excluirse de 
                una comercialización futura comunicándose directamente con nuestros socios comerciales.
              </p><br>

              <p>
                Cuando compra un producto nuestro que se ofrece en conjunto con un producto ofrecido por uno de 
                nuestros socios comerciales, compartiremos su información con estos socios comerciales para 
                que ellos puedan proporcionarle el producto o el servicio incluido en su compra. Estas 
                compañías no están autorizadas a usar la información que le compartimos sobre usted para 
                sus propios fines de comercialización sin pedir primero su autorización.
              </p><br>

              <p>
                <b>Empleadores:</b> ElectoralTech Legal podrá compartir información con empleadores que hayan 
                elegido proporcionar beneficios de empleo a través de ElectoralTech Legal. Esto se 
                hace con fines operativos, como la verificación de los correos electrónicos de los 
                empleados. ElectoralTech Legal no comparte la Información personal que se recopila 
                durante las sesiones de asesoramiento con los empleadores.
              </p><br>

              <p>
                <b>Consumidores relacionados:</b> En circunstancias limitadas, podremos proporcionar información 
                sobre usted a alguien que usted conoce. Por ejemplo, podremos proporcionar información 
                relacionada con su cuenta en ElectoralTech Legal a sus contactos autorizados.
              </p><br>

              <p>
                <b>Entidades del gobierno: </b>ElectoralTech Legal podrá compartir información sobre los consumidores 
                con entidades del gobierno para cumplir con los productos adquiridos por nuestros clientes. 
                Cierta Información personal se convierte en un registro público cuando los documentos se registran 
                ante el gobierno federal o estatal, o ante un tribunal. Por ejemplo, el nombre de una sociedad, 
                domicilio comercial, y nombre de agente registrado se convierten en información pública cuando 
                se registra su acta constitutiva. Un secretario de estado puede publicar esta información en 
                su sitio web o suministrar esta información a terceros a cambio de una tarifa. El Instituto 
                Nacional de Derechos de Autor (INDAUTOR), publica los nombres y direcciones de los titulares 
                de marcas registradas. La Política de privacidad no cubre estas acciones o acciones similares 
                de terceros.
              </p><br>

              <p>
                <b>Publicaciones públicas:</b> ElectoralTech Legal podrá compartir públicamente información sobre 
                los consumidores, por ejemplo, mostrando la información en nuestro sitio web o haciendo que 
                la información se publique en medios de comunicación disponibles para el público, en una 
                cantidad limitada de circunstancias.
              </p><br>

              <p>
                Podremos compartir información públicamente cuando sea necesario para cumplir con un producto 
                que haya sido comprado por un cliente. Por ejemplo, en algunos estados, los nombres comerciales 
                ficticios, incluyendo el nombre y dirección del propietario del negocio, pueden publicarse en un periódico.
              </p><br>

              <p>
                Podremos mostrar testimonios personales de clientes satisfechos recibidos a través de encuestas 
                por correo electrónico en nuestro sitio web u otros medios de mercadotecnia. Si usted desea actualizar 
                o borrar su testimonio, puede comunicarse con nosotros al correo 
                <a href="mailto: contacto@electoraltech.com.mx">contacto@electoraltech.com.mx </a>.
              </p><br>

              <p>
                Cuando los consumidores nos dejan comentarios en el sitio web de ElectoralTech Legal o en otros 
                sitios web públicos, podemos mostrar estos comentarios de los clientes en el sitio web de ElectoralTech 
                Legal. Si usted realiza una reseña que nosotros exhibimos y desea actualizarla o eliminarla, 
                puede comunicarse con nosotros al correo contacto@electoraltech.com.mx, Eliminaremos su reseña 
                si lo solicita o si, a nuestra discreción, decidimos no actualizarla según lo solicitado.
              </p><br>

              <p>
                <b>Solicitudes de agentes registrados:</b> Si usted designa una persona o empresa diferente a 
                ElectoralTech Legal para fungir como el agente registrado para su persona jurídica comercial, 
                ElectoralTech Legal podrá indicarle su nombre e información de contacto a dicha parte, si la persona designada lo solicita.
              </p><br>

              <p>
                <b>Exigencia por ley:</b> ElectoralTech Legal puede divulgar información de los consumidores conforme lo requiera la 
                ley, como p. ej. en respuesta a un emplazamiento, una solicitud legal por parte de una autoridad pública, 
                incluso para cumplir requisitos de seguridad nacional o aplicación de la ley, o un proceso judicial similar, 
                y cuando consideremos de buena fe que la divulgación es necesaria para proteger nuestros derechos, proteger  
                su seguridad o la seguridad de otras personas, investigar acciones fraudulentas o responder a una solicitud legal.
              </p><br>

              <p>
                <b>Partes relacionadas:</b> ElectoralTech Legal podrá divulgar información sobre los consumidores a empresas que 
                controlan, están controladas o se encuentran bajo el control común de ElectoralTech Legal. Si ElectoralTech 
                Legal se fusiona, es adquirida o vendida, o si algunos o todos nuestros activos o acciones son transferidos, 
                podemos divulgar o transferir datos de los consumidores en relación con las transacciones asociadas.
              </p><br>

              <p>
                Además de las circunstancias mencionadas anteriormente, ElectoralTech Legal también podrá utilizar datos 
                anonimizados o totalizados que no puedan relacionarse con ningún consumidor individual para otros fines 
                no mencionados anteriormente.
              </p>

              <p>
                <span style="font-size: 22px;"><b>Resumen de las prácticas de ElectoralTech Legal con respecto a la información de los consumidores</b></span><br>
                El siguiente cuadro resume dónde ElectoralTech Legal recopila cada categoría de información enumerada anteriormente y 
                cómo ElectoralTech Legal comparte cada categoría de datos. ElectoralTech Legal podrá utilizar cada una de las categorías 
                de información recopilada para los fines descritos en la sección anterior, titulada “Cómo utilizamos la información 
                que recopilamos sobre los consumidores”. 
              </p>

              <div class="table-container">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th><b>Categoría de la información</b></th>
                        <th>Identificadores</th>
                        <th>Otros</th>
                        <th>Características protegidas</th>
                        <th>Comercial</th>
                        <th>Internet</th>
                        <th>Geolocalización</th>
                        <th>Audio/visual</th>
                        <th>Profesional/empleo</th>
                      </tr>
                    </thead>
                    <tr>
                      <td><b>Dónde recogemos la información</b></td>
                      <td>
                        Consumidores, consumidores relacionados, empleadores, proveedores de servicios, profesionales, socios comerciales, 
                        disponible a nivel público, partes no relacionadas
                      </td>
                      <td>Consumidores, consumidores relacionados, empleadores, proveedores de servicios, profesionales, 
                        socios comerciales, disponible a nivel público, partes no relacionadas
                      </td>
                      <td>
                        Consumidores, consumidores relacionados, empleadores, proveedores de servicios, profesionales, partes no relacionadas
                      </td>
                      <td>
                        Consumidores, consumidores relacionados, proveedores de servicios, profesionales, socios comerciales, partes no relacionadas
                      </td>
                      <td>
                        Consumidores, proveedores de servicios
                      </td>
                      <td>
                        Consumidores, consumidores relacionados, empleadores, proveedores de servicios, profesionales, socios 
                        comerciales, disponibles a nivel público, partes no relacionadas
                      </td>
                      <td>
                        Consumidores
                      </td>
                      <td>
                        Consumidores, consumidores relacionados, empleadores, disponibles a nivel público, partes no relacionadas
                      </td>
                    </tr>
                    <tr>
                      <td><b>Con quién compartimos la información</b></td>
                      <td>
                        Proveedores de servicios, profesionales, socios comerciales, empleadores, entidades gubernamentales, 
                        el público, agentes registrados, según lo requiera la ley, partes relacionadas
                      </td>
                      <td>
                        Proveedores de servicios, profesionales, socios comerciales, empleadores, entidades gubernamentales, 
                        agentes registrados, según requiera la ley, partes relacionadas
                      </td>
                      <td>
                        Proveedores de servicios, profesionales, socios comerciales, entidades gubernamentales, 
                        el público, según requiera la ley, partes relacionadas
                      </td>
                      <td>
                        Proveedores de servicios, profesionales, socios comerciales, empleadores, entidades gubernamentales, 
                        el público, según requiera la ley, partes relacionadas
                      </td>
                      <td>
                        Proveedores de servicios, socios comerciales, según requiera la ley, partes relacionadas
                      </td>
                      <td>
                        Proveedores de servicios, profesionales, socios comerciales, entidades gubernamentales, el público, 
                        agentes registrados, según requiera la ley, partes relacionadas
                      </td>
                      <td>
                        Proveedores de servicios, según requiera la ley, partes relacionadas
                      </td>
                      <td>
                        Proveedores de servicios, profesionales, socios comerciales, entidades gubernamentales, el público, según requiera la ley, partes relacionadas
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <p>
                <span style="font-size: 22px;"><b>Cómo ElectoralTech Legal utiliza las cookies y tecnologías similares</b></span><br>
                Esta sección amplía la forma en que ElectoralTech Legal utiliza tecnologías de Internet, como las cookies, para recopilar información sobre los consumidores.
              </p><br>

              <p>
                <b>Cookies.</b> ElectoralTech Legal y sus socios pueden utilizar la función de cookies estándar de aplicaciones de los exploradores principales 
                y los proveedores de servicios externos, incluidos los servicios de Google Analytics (Remarketing, Informes de impresiones de la Red de 
                Display de Google, la integración de DoubleClick Campaign Manager e Informes de intereses y datos demográficos de Google Analytics) o 
                emplear las cookies desarrolladas internamente y los códigos de rastreo, que permiten a ElectoralTech Legal almacenar un pequeño 
                conjunto de datos en la computadora de un visitante o cualquier otro dispositivo que utilice un visitante para acceder a nuestro sitio 
                web, sobre su visita al sitio web o uso de otros complementos que intercambian información con ElectoralTech Legal. Asimismo, utilizamos 
                tecnologías similares como JavaScript o ETags, para analizar las tendencias, administrar el sitio web, hacer un seguimiento de los 
                movimientos del usuario dentro del sitio web y para reunir la información demográfica sobre nuestra base de usuarios en su conjunto. 
                Usted puede retirar las cookies persistentes si sigue las indicaciones suministradas en el directorio de “ayuda” de su explorador de Internet.   
              </p><br>

              <p>
                <b>Seguimiento de socios y filiales.</b> socios y filiales, incluyendo Google Analytics, pueden usar cookies y balizas web para recopilar 
                información sobre sus actividades en este y otros sitios web para suministrar publicidad personalizada basada en sus intereses. Esto 
                significa que estos socios y filiales pueden mostrar nuestros anuncios en sitios de Internet con base en sus visitas previas a 
                nuestro sitio web. Junto con nuestros socios y filiales, podemos usar estas cookies y balizas web para informar la manera en que 
                sus impresiones publicitarias, otros usos de servicios publicitarios, e interacciones con estas impresiones publicitarias y 
                servicios publicitarios están relacionados con sus visitas a nuestro sitio.
              </p><br>

              <p>
                Si suprime sus cookies, utiliza un explorador diferente, o compra una computadora nueva, tendrá que renovar su opción de 
                cancelar el envío de publicidad de exhibición en línea. También puede obtener más información sobre el uso que hace 
                Google Analytics de su información en la página de Google: Cómo usa Google la información de los sitios o aplicaciones 
                que usan nuestro servicio. También puede visitar las Configuraciones de anuncios publicitarios de Google Analytics para 
                cancelar el uso de cookies por parte de Google y personalizar los anuncios publicitarios de Google Display Network, y 
                el Complemento de explorador para exclusión de Google Analytics para la web.
              </p><br>

              <p>
                <span style="font-size: 22px;"><b>Enlaces de terceros</b></span><br><br>
                <b>Sitios web de terceros.</b> ElectoralTech Legal puede mantener enlaces a otros sitios web y otros sitios web pueden 
                mantener enlaces a los sitios web de electoraltech.com.mx. Esta Política de privacidad se aplica únicamente a 
                ElectoralTech Legal y no a otros sitios web a los que se puede acceder desde ElectoralTech Legal o que usted 
                utiliza para acceder a ElectoralTech Legal, cada uno de los cuales puede tener políticas de privacidad que sean 
                sustancialmente diferentes de esta Política de privacidad. Si usted visita otros sitios web, ElectoralTech Legal 
                no es responsable de las prácticas de privacidad o el contenido de dichos sitios. Usted tiene la responsabilidad 
                de revisar las políticas de privacidad de sitios web ajenos a ElectoralTech Legal para confirmar que usted los comprende y acepta.
              </p><br>

              <p>
                Widgets de redes sociales. Nuestro sitio web incluye funciones de las redes sociales, como el botón de “me gusta” 
                y Widgets de Facebook, como por ejemplo el botón de “Compartir esto” o mini programas interactivos que se 
                ejecutan en nuestro sitio. Estas funciones pueden recopilar información como su dirección de IP, cuál página 
                usted está visitando en nuestro sitio, y pueden establecer una cookie para habilitar la función de manera que 
                opere de forma adecuada. Las funciones y widgets de redes sociales son alojados por un tercero o alojados 
                directamente en nuestro sitio web. Sus interacciones con estas funciones se rigen por la política de privacidad 
                de la empresa que las suministra.
              </p><br>

              <p>
                <span style="font-size: 22px;"><b>Cómo puede controlar la información que ElectoralTech Legal puede tener sobre usted.</b></span><br>
                Esta sección describe cómo puede acceder y controlar la información que ElectoralTech Legal pueda tener sobre usted. 
                La misma se aplica a todos los consumidores de todo el mundo, incluidas las personas que han comprado en ElectoralTech 
                Legal o que crean que la información sobre ellos pueda habernos sido proporcionada por un tercero.
              </p><br>

              <p>
                Confirmación de la posesión de información relacionada con usted. Ante una solicitud verificada, y 
                sujeto a los intereses de privacidad de terceros y a las limitaciones técnicas, ElectoralTech Legal le 
                proporcionará información sobre si tenemos alguna información relacionada con usted. A discreción exclusiva de 
                ElectoralTech Legal, a menos que la ley exija lo contrario, en algunos casos podremos corregir o eliminar la 
                información mediante una solicitud verificada. No obstante, cabe destacar que en determinadas circunstancias 
                la ley puede exigirnos que conservemos su Información personal, o puede ser necesario que conservemos su 
                Información personal para poder seguir ofreciéndole un producto o servicio a usted o a otro cliente. 
              </p><br>

              <p>
                Si desea enviar una solicitud sobre sus datos, podrá hacerlo mediante solicitud dirigida a 
                <a href="mailto: contacto@electoraltech.com.mx">contacto@electoraltech.com.mx </a> o al 2283 078010. Después de enviar la solicitud, se le pedirá que 
                verifique su dirección de correo electrónico y también se le podrá pedir que proporcione información 
                adicional para verificar su identidad.
              </p><br>

              <p>
                <b>Acceso de usuario y contacto autorizado a información de pedidos.</b> ElectoralTech Legal le dará a usted, o a cualquier persona 
                incluida como contacto autorizado para su cuenta, información sobre su pedido. Esto puede incluir información sobre la 
                naturaleza y el estado de su pedido, información que usted suministró para procesar su pedido, información de pago e 
                información de contacto. Si usted desea que alguien tenga acceso a su pedido, debe incluir a dicha persona como contacto 
                autorizado, pues de no hacerlo, dicha persona no podrá obtener información alguna. Puede confirmar las personas que están 
                incluidas como un contacto autorizado para su cuenta, agregar nuevos contactos autorizados, o eliminar contactos autorizados 
                al hacer clic primero en “Mi cuenta” y luego en “Información de cuenta”. Podemos enviar un correo electrónico de confirmación 
                a su contacto autorizado, en el que le indicaremos que fue agregado como un contacto autorizado.
              </p><br>

              <p>
                <b>Conservación de datos.</b> Como mínimo, conservaremos su información por el tiempo que sea necesario para prestarle servicios, 
                y conforme sea necesario para cumplir con nuestras obligaciones legales, resolver controversias, y exigir el cumplimiento de 
                nuestros contratos. ElectoralTech Legal puede mantener alguno o todos estos datos en sus archivos, incluso después de haber sido eliminados del Sitio.
              </p>

              <p>
                <span style="font-size: 22px;"><b>Información procesada en nombre de terceros</b></span><br><br>
                En algunos casos, ElectoralTech Legal podrá procesar Información de identificación personal en nombre de afiliados externos. 
                ElectoralTech Legal reconoce que, en estos casos, tiene el derecho a acceder a su Información personal. En los casos en los 
                que ElectoralTech Legal no tuviese relación directa con los individuos cuya Información personal procesa, los individuos que 
                quieran acceder, corregir, arreglar o eliminar un dato impreciso, deben dirigir su reclamo al afiliado tercero (el controlador de los datos).
              </p>

              <p>
                <b>¿Cómo se protege la Información personal que recopilamos?</b><br><br>
                Nuestro compromiso con la seguridad de los datos Nos esforzamos por hacer que algunos de nuestros servidores y conexiones incorporen los 
                dispositivos de cifrado y seguridad más modernos. Hemos implementado procedimientos físicos, electrónicos y administrativos para salvaguardar 
                y proteger la información que recopilamos.
              </p>

              <p>
                <b>Venta de información personal. </b>
                ElectoralTech Legal no vende Información personal a terceros y no venderá Información personal. 
                Si, en el futuro, ElectoralTech Legal decide vender Información personal, cualquier Información personal 
                recopilada antes de actualizar esta Política de privacidad será tratada como si el consumidor a la que  
                se refiere la información hubiera presentado una solicitud válida de exclusión de ventas de terceros. 
              </p>
            </div>
          </section>         
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ScrollupSection from "../components/Scrollup/Scrollup";
import HeaderSection from "../components/Header/HeaderOne";
import PerfilBreadcrumb from "../components/Breadcrumb/PerfilBreadcrumb";
import VueRecaptcha from "vue-recaptcha";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import {mapState} from "vuex";
import {db, analytics} from "../firebase";

export default {
  name: "Privacidad",
  components: {
    ScrollupSection,
    HeaderSection,
    PerfilBreadcrumb,
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      titulo: "Política De Privacidad",
      descripcion: "",
      form: {
        nombre: "",
        email: "",
        telefono: "",
        comentario: "",
        preferencia: null
      },
      captcha: false,
      urlProd:
          "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal:
          "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
    };
  },
  validations: {
    form: {
      nombre: {required},
      email: {required, email},
      preferencia: {
        required
      },
      telefono: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      comentario: {required, maxLength: maxLength(100)},
    },
  },
  computed: {
    ...mapState("auth", ["usuarioLog", "logIn"]),
    ...mapState("generals", ["key"]),
    validateCaptcha() {
      return this.$v.$invalid && this.captcha;
    },
    url() {
      return process.env.NODE_ENV == "development"
          ? this.urlLocal
          : this.urlProd;
    },
  },
  methods: {
    onVerify: function (response) {
      if (response) {
        fetch(this.url + "validateCaptcha/" + response)
        const url = process.env.NODE_ENV === "development" ? this.urlLocal : this.urlProd;
        fetch(url + "validateCaptcha/" + response)
            .then((res) => res.json())
            .then((res) => {
              console.info(res);
              this.captcha = true;
            })
            .catch((err) => {
              console.warn(err);
            });
      }
    },
    onExpired: function () {
      console.log("Expired");
      this.captcha = false;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    async submit() {
      analytics.logEvent("Comentario");

      this.$swal({
        icon: "info",
        title: "Guardando comentario",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: true,
        didOpen: () => this.$swal.showLoading(),
      });
      // console.log(this.form);
      const comentData = {
        ...this.form,
        fechaRegistro: Date.now(),
        usuarioLogin: this.logIn ? this.usuarioLog : 0,
      };
      db.ref("comentarios")
          .push(comentData)
          .then((res) => {
            console.log(res);
            this.$swal.close();

            this.form.nombre = "";
            this.form.email = "";
            this.form.telefono = "";
            this.form.comentario = "";
            this.form.preferencia = "";
            this.$v.form.$reset();

            this.$swal({
              icon: "success",
              title:
                  "Gracias por comunicarte, nosotros nos pondremos en contacto contigo",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            });
            this.resetRecaptcha();
            this.captcha = false;
          })
          .catch((err) => {
            console.error(err);
            this.$swal({
              icon: "error",
              title: "Revisa tu conexión a internet e intenta de nuevo",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showCancelButton: true,
            });
          });
    },
  },
};
</script>
<style scoped>
.form-group .error {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.contact-info{
  background: #150653;
}

.text-container{
  margin-left: 8%;
  margin-right: 8%;
  text-align: justify;
}
p{
  font-size: 18px;
}
.contact-box-container{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.table-container{
  margin-top: 50px;
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .table-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1201px) {
  .table-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}


</style>